<template>
	<div id="site-header" class="z-20 duration-200" :class="{'min shadow-md': !isTop}">
		<div class="container px-4 py-2">
			<div class="flex flex-wrap items-center justify-between h-full header-inner">
				<div class="flex items-center justify-center w-1/2 h-full mb-2 lg:justify-start lg:mb-0">
					<img class="object-contain duration-200 logo" src="@/assets/images/logo.png" alt="Goodhill Enterprise">
				</div>
				<div class="flex items-center justify-end w-1/2 gap-x-8">
					<div class="leading-snug text-center">
						<span class="text-sm sm:text-lg leading-normal">Call us at</span>
						<br>
						<a href="tel:+6562697860" class="box-border text-xl duration-200 border-b border-transparent text-siteGreen hover:border-siteGreen">
							<IconPhone class="inline pb-1 sm:hidden" />
							6269 7860
						</a>
					</div>
					<div class="hidden sm:block">
						<a href="/#contact-form" v-if="this.$route.name!='index'" class="block px-4 py-2 text-white duration-200 bg-siteGreen hover:bg-siteGreen-2">Enquire Now</a>
						<a href="#contact-form" v-else v-smooth-scroll class="block px-4 py-2 text-white duration-200 bg-siteGreen hover:bg-siteGreen-2">Enquire Now</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import IconPhone from '@/components/svg/IconPhone.vue'
export default {
	components: {
		IconPhone,
	},
	data () {
		return {
			isTop: true,
		};
	},
	mounted () {
		document.addEventListener('scroll', this.handleScroll, {passive: true});
	},
	methods: {
		handleScroll() {
			if (window.pageYOffset >= 250) {
				if (this.isTop) this.isTop = false;
			} else if ( document.documentElement.scrollTop < 100 ) {
				if (!this.isTop) this.isTop = true;
			}
		},
	},
}
</script>

<style scoped>
#site-header {
	@apply sticky top-0;
	/* height: 5rem; */
	background-color: white;
	backdrop-filter: blur(4px);
}
@media (min-width: 1024px) {
	#site-header.min {
		background-color: rgba(255,255,255,.75);
	}
}
/* @screen lg {
	#site-header {
		height: 5.5rem;
	}
}
@screen xl {
	#site-header {
		height: 7.5rem;
	}
} */

.header-inner {
	/* height: 120px; */
}
.logo {
	/* min-height: 3rem;
	max-height: 4rem;
	height: 48px; */
}
</style>
