<template>
	<div id="mainpage" class="flex flex-col min-h-screen home">
		<SiteHeader />

		<div class="section banner-section">
			<div class="container">
				<div class="lg:h-[600px] banner">
					<div class="flex items-center justify-center h-full py-32 lg:py-0">
						<div class="space-y-8">
							<div class="p-8 text-center text-white bg-[#009B4B80]">
								<h1 class="mb-5 text-3xl font-light font-robotoslab lg:text-5xl">No.1 Soundproof and Fire Rated Wooden Door</h1>
								<h2 class="text-xl lg:text-3xl">We Eliminate the Noise, and Keep you Safe</h2>
							</div>
							<div class="text-center">
								<a href="#contact-form" v-smooth-scroll class="inline-block px-8 py-4 text-lg font-robotoslab duration-200 bg-[#FFFFFFB0] hover:bg-siteGray-2 text-siteGreen lg:text-2xl">
									Enquire Now
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-siteGray-1 section benefits-section">
			<div class="container py-8">
				<div class="space-y-8">
					<h2 class="text-3xl font-bold text-center text-siteGreen font-robotoslab">The Soundproofing that Really Works!</h2>

					<carousel
						class="mx-auto sm:max-w-[80%] md:max-w-[90%] lg:max-w-none xl:max-w-[80%]"
						:dots="true"
						:nav="false"
						:loop="false"
						:autoplay="true"
						:autoHeight="false"
						:autoplaySpeed="500"
						:autoplayTimeout="5000"
						:margin="32"
						:responsive="{
							0: {
								items:1,
							},
							768: {
								items:2,
							},
							1024: {
								items:3,
								dots:false,
								mouseDrag:false,
								touchDrag:false,
								pullDrag:false,
							},
						}">
						<div class="space-y-4">
							<img class="w-full h-auto mx-auto max-w-[200px]" src="@/assets/images/benefits-noise-reduction.png" alt="Up to 70% Noise Reduction" width="300" height="300">
							<h3 class="text-2xl text-center font-robotoslab">Up to 70% Noise Reduction</h3>
							<p>Soundproofing with our unique INNER CORE materials, and addition of “Bat Wings” and “Drop Seal” to the door, we reduce noise up to 70%! You can now sleep in peace, WFH with no distraction.</p>
						</div>
						<div class="space-y-4">
							<img class="w-full h-auto mx-auto max-w-[200px]" src="@/assets/images/benefits-aesthetics.png" alt="Customisable Designs" width="300" height="300">
							<h3 class="text-2xl text-center font-robotoslab">Customisable Designs</h3>
							<p>More than 20 different door designs to choose from; wood finishing look, marble finishing look, to plain designs.</p>
						</div>
						<div class="space-y-4">
							<img class="w-full h-auto mx-auto max-w-[200px]" src="@/assets/images/benefits-fire-rated.png" alt="Soundproof + Fire-Rated" width="300" height="300">
							<h3 class="text-2xl text-center font-robotoslab">Soundproof + Fire-Rated</h3>
							<p>What’s better than soundproof doors? A Soundproof + Fire-resistant door! Our doors can be made in Fire-rated materials as well to provide both fire-rated and soundproof capabilities.</p>
						</div>
					</carousel>

				</div>
			</div>
		</div>

		<div class="bg-white section technology-section">
			<div class="container py-8">
				<div class="space-y-16">
					<div class="space-y-8">
						<h2 class="text-3xl font-bold text-center text-siteGreen font-robotoslab">How do we Block Noise Out?</h2>
						<p class="text-center lg:text-xl">Soundproof doors look the same as regular door, but works different.<br>With our methodology, we are also able to enhance your existing door to become a soundproof door.</p>
						<div class="">
							<hr class="border-siteGray-2">
							<hr class="border-white">
						</div>
					</div>

					<div class="space-y-8">
						<img class="mx-auto" src="@/assets/images/core-materials.png" alt="Core Materials" width="auto" height="auto" loading="lazy">
						<p class="text-center lg:text-xl">Our soundproof doors come in 2 specially composed materials. <br class="hidden md:block">Standard grade for common use, and Grade A for maximum noise reduction + fire-rate.</p>
						<div class="">
							<hr class="border-siteGray-2">
							<hr class="border-white">
						</div>
					</div>

					<div class="space-y-8">
						<div class="flex justify-center gap-4">
							<img class="w-1/2 h-auto lg:w-auto" src="@/assets/images/bat-wing-01.jpg" alt="Bat Wing" width="384" height="459" loading="lazy">
							<img class="w-1/2 h-auto lg:w-auto" src="@/assets/images/bat-wing-02.jpg" alt="Bat Wing + Drop Seal" width="406" height="459" loading="lazy">
						</div>
						<p class="text-center lg:text-xl">Bat Wing forms a double seal around the edges, addition noise blocker, increase noise reduction.</p>
						<div class="">
							<hr class="border-siteGray-2">
							<hr class="border-white">
						</div>
					</div>

					<div class="space-y-8">
						<img class="object-cover object-left min-h-[200px] mx-auto" src="@/assets/images/drop-seal-01.jpg" alt="Core Materials" width="1062" height="314" loading="lazy">
						<p class="text-center lg:text-xl">Drop Seal that tucks away when door is open and tightly seal the gap when closed. Some say it blocks insects too!</p>
					</div>

				</div>
			</div>
		</div>

		<div class="bg-siteGray-1 section video-section">
			<div class="container py-8">
				<div class="space-y-8">
					<h2 class="text-3xl font-bold text-center text-siteGreen font-robotoslab">Goodhill Soundproof Door Test Result</h2>
					<p class="text-center lg:text-xl">We have done numerous experiments with different combination of material to attain the highest noise reduction.<br>Check out our video, see it for yourself the difference of soundproof door.</p>
					<!-- <img class="mx-auto shadow-md" src="@/assets/images/video-placeholder.jpg" alt="Core Materials" width="640" height="360"> -->
					<video width="640" height="360" preload="none" poster="@/assets/images/video-poster.jpg" controls class="mx-auto">
						<source src="@/assets/videos/goodhill-soundproof-door-video.mp4" type="video/mp4">
						Your browser does not support the video tag.
					</video>
				</div>
			</div>
		</div>

		<div class="bg-white section range-section">
			<div class="container py-8">
				<div class="space-y-8">
					<h2 class="text-3xl font-bold text-center text-siteGreen font-robotoslab">Our Range of Soundproof Doors</h2>
					<p class="text-center lg:text-xl">While soundproofing the doors, we do not compromise on the designs of our doors.<br> More than 20 different designs to choose from.</p>

					<carousel
						class="mx-auto max-w-[75%] md:max-w-none"
						:dots="true"
						:nav="false"
						:loop="false"
						:autoplay="true"
						:autoHeight="false"
						:autoplaySpeed="500"
						:autoplayTimeout="5000"
						:margin="16"
						:responsive="{
							0: {
								items:1,
							},
							640: {
								items:2,
							},
							768: {
								items:3,
							},
							1024: {
								items:4,
							},
						}">

						<img src="@/assets/images/GCB-008.jpg" alt="GCB-008" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-010.jpg" alt="GCB-010" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-064.jpg" alt="GCB-064" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-097.jpg" alt="GCB-097" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-220.jpg" alt="GCB-220" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-338.jpg" alt="GCB-338" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-340.jpg" alt="GCB-340" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-360.jpg" alt="GCB-360" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-512.jpg" alt="GCB-512" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-555.jpg" alt="GCB-555" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-571.jpg" alt="GCB-571" width="400" height="864" loading="lazy">
						<img src="@/assets/images/GCB-583.jpg" alt="GCB-583" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-700.jpg" alt="GCB-700" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-735 (LUXUS WHITE).jpg" alt="GCB-735 (LUXUS WHITE)" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-800.jpg" alt="GCB-800" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-854.jpg" alt="GCB-854" width="400" height="863" loading="lazy">
						<img src="@/assets/images/GCB-883.jpg" alt="GCB-883" width="400" height="864" loading="lazy">
						<img src="@/assets/images/GCB-892 (SMOKED ASH).jpg" alt="GCB-892 (SMOKED ASH)" width="400" height="863" loading="lazy">

					</carousel>

				</div>
			</div>
		</div>

		<div class="container mx-auto"><hr class="border-siteGray-2"><hr class="border-white"></div>

		<div class="bg-white section rated-section">
			<div class="container py-8">
				<div class="lg:w-4/5 mx-auto">
					<div class="flex flex-wrap -mx-4">
						<div class="w-full px-4 lg:w-1/2 xl:w-3/5">
							<h2 class="text-3xl font-bold mb-5 text-siteGreen font-robotoslab">Goodhill Fire-rated Door</h2>
							<h4 class="font-bold font-robotoslab text-siteGreen lg:text-xl">Our Fire-rated Door are:</h4>
							<ul class="pl-5 list-disc mb-6">
								<li>Compliance to SCDF requirements</li>
								<li>Suitable for commercial and office buildings</li>
								<li>Customised dimensions available</li>
							</ul>
							<h4 class="font-bold font-robotoslab text-siteGreen lg:text-xl">Metal Fire-rated Door Metal Fire-rated Door <br class="hidden lg:block">(w Wood Grain Coating)</h4>
							<ul class="pl-5 list-disc mb-6">
								<li>Up to 4-hour fire-rated</li>
								<li>More than 8 different wood finishing</li>
							</ul>
							<h4 class="font-bold font-robotoslab text-siteGreen lg:text-xl">Wooden Fire-rated door</h4>
							<ul class="pl-5 list-disc">
								<li>0.5 to 2-hour fire rated</li>
								<li>Veneer / Laminate surface finishing</li>
							</ul>
						</div>
						<div class="w-full px-4 lg:w-1/2 xl:w-2/5">
							<div class="w-3/5 mt-8 mb-8 mx-auto lg:mt-0">
								<img src="@/assets/images/product-023.jpg"/>
							</div>
							<div class="flex flex-wrap">
								<div class="w-1/4"><img src="@/assets/images/color-09.jpg"></div>
								<div class="w-1/4"><img src="@/assets/images/color-10.jpg"></div>
								<div class="w-1/4"><img src="@/assets/images/color-11.jpg"></div>
								<div class="w-1/4"><img src="@/assets/images/color-12.jpg"></div>
								<div class="w-1/4"><img src="@/assets/images/color-13.jpg"></div>
								<div class="w-1/4"><img src="@/assets/images/color-14.jpg"></div>
								<div class="w-1/4"><img src="@/assets/images/color-15.jpg"></div>
								<div class="w-1/4"><img src="@/assets/images/color-16.jpg"></div>
								<div class="w-1/4"><img src="@/assets/images/color-17.jpg"></div>
								<div class="w-1/4"><img src="@/assets/images/color-18.jpg"></div>
								<div class="w-1/4"><img src="@/assets/images/color-19.jpg"></div>
								<div class="w-1/4"><img src="@/assets/images/color-20.jpg"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-siteGray-1 section testimonials-section">
			<div class="container py-8">
				<div class="space-y-8">
					<h2 class="text-3xl font-bold text-center text-siteGreen font-robotoslab">Good reviews from Happy Customers</h2>
					<p class="text-center lg:text-xl">We are deeply appreciative to all our customers that have entrust us with soundproofing your doors.<br class="hidden md:inline"> Here are some good words from them:</p>

					<carousel
						class="mx-auto"
						:dots="true"
						:nav="false"
						:loop="false"
						:autoplay="true"
						:autoHeight="false"
						:autoplaySpeed="500"
						:autoplayTimeout="5000"
						:margin="16"
						:items="1">

						<div class="space-y-6">
							<p class="md:max-w-[60%] mx-auto italic">“They are very prompt and professional with their service and consultation. I was looking for soundproof bedroom doors, can tell they are really good at it. The doors are done to satisfaction, and good quality, will definitely recommend them.”</p>
							<div class="font-bold text-center font-robotoslab">— Shaun Ang<span class="text-sm font-normal">, Punggol</span></div>
						</div>

						<div class="space-y-6">
							<p class="md:max-w-[60%] mx-auto italic">“Decided to better soundproof my door as both me and wife WFH, when both on call sometimes can be quite distracting. Goodhill’s soundproof door really helps, and are of good quality. Will recommend them to friends.”</p>
							<div class="font-bold text-center font-robotoslab">— Jonathan Lee<span class="text-sm font-normal">, Bishan</span></div>
						</div>

						<div class="space-y-6">
							<p class="md:max-w-[60%] mx-auto italic">“Ah wei is extremely patience and friendly when he is consulting on how to soundproof my rooms. I am convinced by his experience and knowledge. More importantly, the soundproof doors work! And to expectation. Thank you Goodhill, now my kids, myself, can sleep better.”</p>
							<div class="font-bold text-center font-robotoslab">— Jane Chua<span class="text-sm font-normal">, Seng Kang</span></div>
						</div>

						<div class="space-y-6">
							<p class="md:max-w-[60%] mx-auto italic">“Need to soundproof my study room and bedroom door. Found Goodhill, and they came for a site visit and measurement. Very professional, and not pushy. The turn-around time was quick too. Can tell they are good at what they doing, knowledge and professional. Thank you”</p>
							<div class="font-bold text-center font-robotoslab">— Ong Yew Koon<span class="text-sm font-normal">, Jurong</span></div>
						</div>

						<div class="space-y-6">
							<p class="md:max-w-[60%] mx-auto italic">Cos my parents or kids usually watch TV quite loudly, so most of time it is very distracting even when doors are close. Found Goodhill, and is convince by how they soundproof the door. Went to their showroom for a demo and is impress. Happy with their soundproofing doors. Thank you.”</p>
							<div class="font-bold text-center font-robotoslab">— Farah<span class="text-sm font-normal">, Woodlands</span></div>
						</div>

					</carousel>

				</div>
			</div>
		</div>

		<div class="bg-white section client-section">
			<div class="container py-8">
				<div class="space-y-8">
					<h2 class="text-3xl font-bold text-center text-siteGreen font-robotoslab">Our Featured Clienteles</h2>

					<carousel
						:dots="true"
						:nav="false"
						:loop="false"
						:autoplay="true"
						:autoHeight="false"
						:autoplaySpeed="500"
						:autoplayTimeout="5000"
						:margin="16"
						:responsive="{
							0: {
								items:2,
							},
							768: {
								items:3,
							},
							1024: {
								items:6,
							},
						}">

						<img src="@/assets/images/brand-01.jpg" loading="lazy">
						<img src="@/assets/images/brand-02.jpg" loading="lazy">
						<img src="@/assets/images/brand-03.jpg" loading="lazy">
						<img src="@/assets/images/brand-04.jpg" loading="lazy">
						<img src="@/assets/images/brand-05.jpg" loading="lazy">
						<img src="@/assets/images/brand-06.jpg" loading="lazy">
						<img src="@/assets/images/brand-07.jpg" loading="lazy">
						<img src="@/assets/images/brand-08.jpg" loading="lazy">
						<img src="@/assets/images/brand-09.jpg" loading="lazy">
						<img src="@/assets/images/brand-10.jpg" loading="lazy">
						<img src="@/assets/images/brand-11.jpg" loading="lazy">
						<img src="@/assets/images/brand-12.jpg" loading="lazy">
						<img src="@/assets/images/brand-13.jpg" loading="lazy">
						<img src="@/assets/images/brand-14.jpg" loading="lazy">
						<img src="@/assets/images/brand-15.jpg" loading="lazy">
						<img src="@/assets/images/brand-16.jpg" loading="lazy">
						<img src="@/assets/images/brand-17.jpg" loading="lazy">
						<img src="@/assets/images/brand-18.jpg" loading="lazy">

					</carousel>

				</div>
			</div>
		</div>

		<div class="bg-siteGray-1 section form-section">
			<div class="container py-8">
				<div class="space-y-8">
					<h2 class="text-3xl font-bold text-center text-siteGreen font-robotoslab">Send Us An Enquiry</h2>

					<div class="w-full px-4 mx-auto lg:w-2/3">
						<ContactForm id="contact-form" style="scroll-margin-top: 160px"/>
					</div>

				</div>
			</div>
		</div>

		<div class="bg-white section contact-section">
			<div class="container py-8">
				<div class="space-y-8 text-center">
					<h2 class="text-3xl font-bold text-center text-siteGreen font-robotoslab">Feel Free to Reach Us At</h2>
					<div class="space-y-4 text-xl">
						<p class="font-bold">Goodhill Enterprise (S) Pte Ltd</p>
						<p>30, Kranji Loop, #04-01<br>
              TimMac @ Kranji (Blk A)<br>
              Singapore 739570</p>
						<p>Call us @ <a class="duration-200 border-b border-transparent text-siteGreen hover:border-siteGreen" href="tel:+6562697860">6269 7860</a></p>
						<p>Email: <a class="duration-200 border-b border-transparent text-siteGreen hover:border-siteGreen" href="mailto:sales@goodhill.com">sales@goodhill.com</a></p>
						<p>Opening Hours: Mon to Sat, 9am to 5pm</p>
					</div>
				</div>
			</div>
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15954.33283019767!2d103.73930793237314!3d1.425691579373235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da125efc2bf343%3A0xc66d44894ec61117!2sGoodhill+Enterprise+(S)+Pte+Ltd!5e0!3m2!1sen!2ssg!4v1543909892249" width="100%" height="400" frameborder="0" style="border:0" allowfullscreen="" loading="lazy"></iframe>
		</div>

		<SiteFooter />
	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import ContactForm from '@/components/ContactForm.vue'

export default {
	name: 'index',
	components: {
		SiteHeader,
		SiteFooter,
		ContactForm,
	}
}
</script>

<style>
	.banner-section {
		background-image: url('~@/assets/images/banner.jpg');
		@apply bg-cover bg-right sm:bg-center bg-no-repeat;
	}
</style>